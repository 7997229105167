<script>
import Slick from 'vue-slick';

export default {
  components: { Slick },
  props: ['sliderReference', 'sliderOptions'],
  data() {
    return {
      slide: 0,
      leftArrow: null,
      rightArrow: null,
    };
  },
  mounted() {
    window.bus.$on('devise-loaded', () => {
      this.reInit(this.sliderReference);
    });
  },
  methods: {
    reInit() {
      this.$nextTick(() => {
        this.$refs[this.sliderReference].reSlick();
        this.slide = this.getCurrentSlide(this.sliderReference);
      });
    },
    toSlide(i) {
      this.$refs[this.sliderReference].goTo(i);
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    nextSlide() {
      this.$refs[this.sliderReference].next();
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    prevSlide() {
      this.$refs[this.sliderReference].prev();
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    getCurrentSlide() {
      if (typeof this.$refs[this.sliderReference] !== 'undefined') {
        return this.$refs[this.sliderReference].currentSlide();
      }
      return 0;
    },
  },
};
</script>
