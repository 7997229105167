<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    viewBox="0 0 14 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6287 12.3938L3.36185 22.6297C2.86669 23.1234 2.06392 23.1234 1.56881 22.6297L0.371356 21.4359C-0.122957 20.9431 -0.123907 20.1443 0.369243 19.6503L8.50588 11.5L0.369243 3.34965C-0.123907 2.85567 -0.122957 2.05694 0.371356 1.56411L1.56881 0.370252C2.06397 -0.123417 2.86674 -0.123417 3.36185 0.370252L13.6286 10.6062C14.1238 11.0998 14.1238 11.9002 13.6287 12.3938Z"
      fill="#3D4852"
    />
  </svg>
</template>

<script>
export default {
  name: 'FiveGSVG',
  props: {
    width: {
      type: Number,
      default: 14,
    },
  },
};
</script>
