/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';

// Devise
import Devise from 'devisephp-interface';

// Devise requires a bus, vue-router and vuex. We initialize these in your application
// so that both apps can share the same store and router. All devise vuex
// is namespaced under the "devise" namespace.
import { EventBus } from './event-bus.js';
window.bus = EventBus;
import router from './router/route.config.js';
import store from './vuex/store';
import { sync } from 'vuex-router-sync';
sync(store, router);

// We initialize the Devise plugin and pass our router, store, and bus to share
// // these resources so that your application can tap into them.
Vue.use(Devise, {
  store: store,
  router: router,
  bus: window.bus,
  options: {
    adminClass: '',
  },
});

import Headroom from 'headroom.js';
import vSelect from 'vue-select';
import DiagramFeature from './components/DiagramFeature.vue';
import Navigation from './components/Navigation.vue';
import FeatureDiagram from './components/FeatureDiagram.vue';
import Blog from './components/Blog.vue';
import Slider from './components/Slider.vue';
import Products from './components/Products.vue';
import Industries from './components/Industries.vue';
import ProductFeaturesTable from './components/ProductFeaturesTable.vue';
import IndustriesExplorer from './components/IndustriesExplorer.vue';
import TwoToneHero from './components/TwoToneHero.vue';
import FivegSvg from './components/svgs/FivegSvg.vue';
import ReadySvg from './components/svgs/ReadySvg.vue';
import GearsSvg from './components/svgs/GearsSvg.vue';
import LocationSvg from './components/svgs/LocationSvg.vue';
import LockSvg from './components/svgs/LockSvg.vue';
import WifiSvg from './components/svgs/WifiSvg.vue';
import ArrowSvg from './components/svgs/ArrowSvg.vue';
import FeatureDescriptionGrid from './components/FeatureDescriptionGrid.vue';
import FeatureGridItem from './components/FeatureGridItem.vue';
import Careers from './components/Careers.vue';
import ContactForm from './components/ContactForm.vue';
import GeneralText from './components/GeneralText.vue';
import PurpleBlockQuote from './components/PurpleBlockQuote.vue';
import CaseStudySlider from './components/CaseStudySlider.vue';

Vue.component('v-select', vSelect);
Vue.component('diagram-feature', DiagramFeature);
Vue.component('navigation', Navigation);
Vue.component('feature-diagram', FeatureDiagram);
Vue.component('blog', Blog);
Vue.component('slider', Slider);
Vue.component('products', Products);
Vue.component('industries', Industries);
Vue.component('product-features-table', ProductFeaturesTable);
Vue.component('industries-explorer', IndustriesExplorer);
Vue.component('two-tone-hero', TwoToneHero);
Vue.component('fiveg-svg', FivegSvg);
Vue.component('ready-svg', ReadySvg);
Vue.component('feature-description-grid', FeatureDescriptionGrid);
Vue.component('feature-grid-item', FeatureGridItem);
Vue.component('gears-svg', GearsSvg);
Vue.component('location-svg', LocationSvg);
Vue.component('lock-svg', LockSvg);
Vue.component('wifi-svg', WifiSvg);
Vue.component('arrow-svg', ArrowSvg);
Vue.component('careers-index', Careers);
Vue.component('contact-form', ContactForm);
Vue.component('general-text', GeneralText);
Vue.component('purple-block-quote', PurpleBlockQuote);
Vue.component('case-study-slider', CaseStudySlider);

import AddIcon from 'vue-ionicons/dist/ios-add.vue';
import RemoveIcon from 'vue-ionicons/dist/ios-remove.vue';
import LinkedInIcon from 'vue-ionicons/dist/js/logo-linkedin';

Vue.component('add-icon', AddIcon);
Vue.component('remove-icon', RemoveIcon);
Vue.component('linkedin-icon', LinkedInIcon);

import VueTippy from 'vue-tippy';
Vue.use(VueTippy);

import VueProgress from 'vue-progress-path';
Vue.use(VueProgress);

new Vue({
  el: '#plumcases-app',
  router: router,
  mounted() {
    this.initHeader();
  },
  methods: {
    initHeader() {
      window.bus.$on('devise-loaded', function () {
        var myElement = document.querySelector('#navigation');

        if (myElement) {
          var headroom = new Headroom(myElement);
          headroom.init();
        }
      });
    },
  },
});
