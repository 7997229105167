/* eslint-disable max-len */
const actions = {
  // Blogs
  getBlogs(context) {
    return new Promise(resolve => {
      window.axios
        .get(context.state.devise.api.baseUrl + 'blogs/')
        .then(function(response) {
          context.commit('setBlogs', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createBlog(context, blog) {
    return new Promise(resolve => {
      window.axios
        .post(context.state.devise.api.baseUrl + 'blogs/', blog)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: blog.title + ' has been created.',
          });
          context.commit('createBlogs', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateBlog(context, payload) {
    return new Promise(resolve => {
      window.axios
        .put(
          context.state.devise.api.baseUrl + 'blogs/' + payload.blog.id,
          payload.data
        )
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: payload.data.title + ' has been saved.',
          });
          context.commit('updateBlogs', {
            blog: payload.blog,
            data: response.data,
          });
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteBlog(context, blog) {
    return new Promise(resolve => {
      window.axios
        .delete(context.state.devise.api.baseUrl + 'blogs/' + blog.id)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: blog.title + ' has been deleted.',
          });
          context.commit('deleteBlogs', blog);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Products
  getProducts(context) {
    return new Promise(resolve => {
      window.axios
        .get(context.state.devise.api.baseUrl + 'products/')
        .then(function(response) {
          context.commit('setProducts', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createProduct(context, product) {
    return new Promise(resolve => {
      window.axios
        .post(context.state.devise.api.baseUrl + 'products/', product)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: product.name + ' has been created.',
          });
          context.commit('createProducts', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateProduct(context, payload) {
    return new Promise(resolve => {
      window.axios
        .put(
          context.state.devise.api.baseUrl + 'products/' + payload.product.id,
          payload.data
        )
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: payload.data.name + ' has been saved.',
          });
          context.commit('updateProducts', {
            product: payload.product,
            data: response.data,
          });
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteProduct(context, product) {
    return new Promise(resolve => {
      window.axios
        .delete(context.state.devise.api.baseUrl + 'products/' + product.id)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: product.name + ' has been deleted.',
          });
          context.commit('deleteProducts', product);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Careers
  getCareers(context) {
    return new Promise(resolve => {
      window.axios
        .get(context.state.devise.api.baseUrl + 'careers/')
        .then(function(response) {
          context.commit('setCareers', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createCareer(context, career) {
    return new Promise(resolve => {
      window.axios
        .post(context.state.devise.api.baseUrl + 'careers/', career)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: career.name + ' has been created.',
          });
          context.commit('createCareers', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateCareer(context, payload) {
    return new Promise(resolve => {
      window.axios
        .put(
          context.state.devise.api.baseUrl + 'careers/' + payload.career.id,
          payload.data
        )
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: payload.data.name + ' has been saved.',
          });
          context.commit('updateCareers', {
            career: payload.career,
            data: response.data,
          });
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteCareer(context, career) {
    return new Promise(resolve => {
      window.axios
        .delete(context.state.devise.api.baseUrl + 'careers/' + career.id)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: career.name + ' has been deleted.',
          });
          context.commit('deleteCareers', career);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Industries
  getIndustries(context) {
    return new Promise(resolve => {
      window.axios
        .get(context.state.devise.api.baseUrl + 'industries/')
        .then(function(response) {
          context.commit('setIndustries', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createIndustry(context, industry) {
    return new Promise(resolve => {
      window.axios
        .post(context.state.devise.api.baseUrl + 'industries/', industry)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: industry.name + ' has been created.',
          });
          context.commit('createIndustry', response.data);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateIndustry(context, payload) {
    return new Promise(resolve => {
      window.axios
        .put(
          context.state.devise.api.baseUrl +
            'industries/' +
            payload.industry.id,
          payload.data
        )
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: payload.data.name + ' has been saved.',
          });
          context.commit('updateIndustry', {
            industry: payload.industry,
            data: response.data,
          });
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteIndustry(context, industry) {
    return new Promise(resolve => {
      window.axios
        .delete(context.state.devise.api.baseUrl + 'industries/' + industry.id)
        .then(function(response) {
          window.deviseSettings.$bus.$emit('showMessage', {
            title: 'Success!',
            message: industry.name + ' has been deleted.',
          });
          context.commit('deleteIndustry', industry);
          resolve(response);
        })
        .catch(function(error) {
          window.deviseSettings.$bus.$emit('showError', error);
        });
    }).catch(function(error) {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },
};

export default actions;
