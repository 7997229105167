<template>
  <div class="bg-purple-600 w-full pt-12 pb-8">
    <div class="flex flex-col justify-center items-center">
      <div class="flex max-w-4xl">
        <div
          class="text-[300px] text-[#95bf5a] font-serif -mt-16 leading-tight h-64 w-64 "
        >
          “
        </div>
        <div
          class="pl-8 text-white text-3xl leading-tight font-headline font-bold"
        >
          {{ devise.quote.text }}
        </div>
      </div>
      <div
        class="max-w-4xl mt-4 text-white font-bold text-sm text-right w-full pr-12 lg:pr-0"
      >
        - {{ devise.author.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PurpleBlockQuote',
};
</script>
