<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FeatureDiagram',
  data() {
    return {
      currentlySelectedFeature: null,
    };
  },
  computed: {
    ...mapGetters('devise', ['breakpoint']),
  },
  methods: {
    selectFeature(feature) {
      this.currentlySelectedFeature = feature;
    },
  },
};
</script>
