<template>
  <div class="content-container px-8 md:py-8 text-center">
    <simple-table
      prefix=""
      class="dvs-w-full"
      :data="rows"
      :columns="columns"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
import SimpleTable from './SimpleTable';
export default {
  components: {
    SimpleTable,
  },

  props: {
    highlight: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      rows: [
        {
          name: '<span class="font-bold">Plum Basic <sup>&reg;</sup></span>',
          image:
            '<div><img src="/imgs/products/plum-basic-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description: 'The original Plum Case. Uses single modem.',
          cellularCarriers: 2,
          antennas: 5,
          connectedDevices: 30,
          cellularGateway: 'IBR 900-1200 M-B',
          mimo: '2x2',
          ports: '-',
          powerSystem: '85 Wh',
          highlighted: this.highlight === '0',
        },
        {
          name: '<span class="font-bold">Plum Explorer&trade;</span>',
          image:
            '<div><img src="/imgs/products/explorer-isolated-main-webopt-500px.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'The most portable Plum Case. The smallest, lightest weight case with dual modem.',
          cellularCarriers: 4,
          antennas: 8,
          connectedDevices: 30,
          cellularGateway: 'IBR 900-1200 M-B <br/> MC400LP6',
          mimo: '2x2<br />2x2',
          ports: '2 ports lighted power button and univeral charging',
          powerSystem: 'PPB w/ 1 Smart Battery (99.6 Wh)',
          highlighted: this.highlight === '1',
        },
        {
          name: '<span class="font-bold">Plum Extended<sup>&reg;</sup></span>',
          image:
            '<div><img src="/imgs/products/plum-extended-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'The most balanced Plum Case. With a combination of speed and portability, the  Extended is the smallest case.',
          cellularCarriers: 4,
          antennas: 10,
          connectedDevices: 30,
          cellularGateway: 'IBR 900-1200 M-B <br/> MC400-1200',
          mimo: '2x2 <br /> 4x4',
          ports: '2 ports lighted power button and univeral charging',
          powerSystem: 'PPB w/ 1 Smart Battery (99.6 Wh)',
          highlighted: this.highlight === '2',
        },
        {
          name: '<span class="font-bold">Plum Enhanced<sup>&reg;</sup></span>',
          image:
            '<div><img src="/imgs/products/plum-enhanced-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'Designed for activities that use substantial bandwidth.',
          cellularCarriers: 4,
          antennas: 16,
          connectedDevices: 100,
          cellularGateway: 'IBR1700-1200 M-B​ <br />MC400-1200​',
          mimo: '4x4 <br> 4x4',
          ports:
            '4 ports: 2xRJ45 ethernet, lighted power button, and universal charging​​',
          powerSystem: 'PPB w/ 2 Smart Battery (99.6 Wh ea.)',
          highlighted: this.highlight === '3',
        },
        {
          name: '<span class="font-bold">Plum Enterprise<sup>&reg;</sup> 5G Embedded</span>',
          image:
            '<div><img src="/imgs/products/plum-enterprise-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'The largest capacity and most capabilities of any Plum Case. The Enterprise can support a temporary datacenter.​',
          cellularCarriers: 4,
          antennas: 14,
          connectedDevices: 100,
          cellularGateway: 'E3000-5GB​ <br />MC400-5G​',
          mimo: '4x4 <br> 4x4',
          ports:
            '6 ports: 4xRJ45 ethernet, lighted power button, and universal charging​',

          powerSystem: 'PPB w/ 4 Smart Battery (99.6 Wh ea.)',
          highlighted: this.highlight === '4',
        },
        {
          name: '<span class="font-bold">Plum Sat III&trade;</span>',
          image:
            '<div><img src="/imgs/products/plum-sat-iii-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'The ultimate in remote connectivity. The Plum SAT III uses cellular and satellite antennas for mission-critical​ communication.',
          cellularCarriers: 4,
          antennas: 11,
          connectedDevices: 30,
          cellularGateway: 'IBR 900-1200 M-B <br/> MC400-1200',
          mimo: '2x2 <br> 4x4',
          ports: '2 ports: lighted power button, and universal charging​​',
          powerSystem: 'PPB w/ 4 Smart Battery (99.6 Wh ea.)',
          highlighted: this.highlight === '5',
        },
        {
          name: '<span class="font-bold">Plum Pak<sup>&reg;</sup></span>',
          image:
            '<div><img src="/imgs/products/plum-pak-basic-thumbnail-image.png" style="min-height:100px;min-width:100px"></div>',
          description:
            'Redesigned for teams on the move, the Plum Pak has the same power and capabilities as either the Plum Basic or Plum Explorer',
          cellularCarriers: 4,
          antennas: 8,
          connectedDevices: 30,
          cellularGateway: 'IBR900-1200 M-B​ <br />MC400LP6​',
          mimo: '2x2 <br /> 2x2',
          ports: '-',
          powerSystem: '85 Wh',
          highlighted: this.highlight === '6',
        },
      ],
      columns: [
        {
          name: 'Name',
          property: 'name',
        },
        {
          name: 'Image',
          property: 'image',
          type: 'image',
        },
        {
          name: 'Description',
          property: 'description',
        },
        {
          name: 'Cellular Carriers',
          property: 'cellularCarriers',
        },
        {
          name: 'Antennas',
          property: 'antennas',
        },
        {
          name: 'Connected Devices',
          property: 'connectedDevices',
        },
        {
          name: 'Cellular Gateway',
          property: 'cellularGateway',
        },
        {
          name: 'Cellular MIMO',
          property: 'mimo',
        },
        {
          name: 'Standar External Ports',
          property: 'ports',
        },
        {
          name: 'Power System',
          property: 'powerSystem',
        },
      ],
    };
  },
};
</script>
