<script>
import dayjs from 'dayjs';

export default {
  methods: {
    calculateDate: function(date) {
      return dayjs(date).format('MMMM D YYYY h:mm A');
    },
  },
};
</script>
