<template>
  <div>
    <div class="opacity-75 bg-black fixed z-30 pin" />
    <div
      class="absolute min-w-1/2 absolute-center bg-white rounded-sm shadow-lg z-40"
    >
      <div
        class="bg-black p-4 absolute top-0 right-0 cursor-pointer"
        style="width:80px;height:80px;margin-top:-80px;"
      >
        <div class="close white" @click="$emit('close')" />
      </div>
      <div class="bg-abs-white text-left p-8 rounded-b-sm">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
