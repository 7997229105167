<template>
  <div>
    <div
      class="grid overflow-x-auto overflow-y-hidden"
      style="min-width:1000px;"
      :style="gridStyles"
    >
      <div
        v-for="(column, index) in columns"
        :key="index"
        class="font-bold mb-2 pb-2 border-b min-w-40"
        @click="sortByColumn(column)"
      >
        {{ column.name }}
        <template v-if="column === sortBy">
          <arrow-down v-if="sortDir === 'desc'" />
          <arrow-up v-else />
        </template>
      </div>
    </div>
    <div
      v-for="(record, dataKey) in sortedData"
      :key="dataKey"
      class="grid"
      style="min-width:1000px;"
      :style="gridStyles"
    >
      <div
        v-for="(column, colIndex) in columns"
        :key="colIndex"
        v-html="record[1][column.property]"
        class="p-4"
        :class="{ 'bg-purple-300': record[1].highlighted === true }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  components: {
    ArrowDown: () =>
      import(
        /* webpackChunkName: "devise-icons" */ 'vue-ionicons/dist/ios-arrow-down.vue'
      ),
    ArrowUp: () =>
      import(
        /* webpackChunkName: "devise-icons" */ 'vue-ionicons/dist/ios-arrow-up.vue'
      ),
  },
  props: {
    columns: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      sortBy: null,
      sortDir: null,
      headerHeight: 0,
    };
  },
  computed: {
    gridStyles() {
      return {
        'grid-template-columns': `repeat(${
          Object.keys(this.columns).length
        }, minmax(0, 1fr))`,
      };
    },

    sortedData() {
      const self = this;
      const sortable = [];
      for (const record in this.data) {
        if (this.data.hasOwnProperty(record)) {
          sortable.push([record, this.data[record]]);
        }
      }

      if (this.sortBy !== null) {
        sortable.sort((a, b) => {
          if (self.sortBy.property) {
            if (typeof a[1][self.sortBy.property] === 'string') {
              return self.sortString(
                a[1][self.sortBy.property],
                b[1][self.sortBy.property],
                self.sortDir
              );
            }
            return self.sortNumber(
              a[1][self.sortBy.property],
              b[1][self.sortBy.property],
              self.sortDir
            );
          }
          if (typeof a[0] === 'string') {
            return self.sortString(a[0], b[0], self.sortDir);
          }
          return self.sortNumber(a[0], b[0], self.sortDir);
        });
      }

      return sortable;
    },
  },
  methods: {
    sortByColumn(column) {
      // Toggling same column
      if (this.sortBy === column) {
        if (this.sortDir === 'desc') {
          this.sortDir = null;
          this.sortBy = null;
        } else if (this.sortDir === 'asc') {
          this.sortDir = 'desc';
        } else {
          this.sortDir = 'asc';
        }
      } else {
        this.sortBy = column;
        this.sortDir = 'asc';
      }
    },
    sortNumber(a, b, direction) {
      if (direction === 'desc') {
        return b - a;
      }
      return a - b;
    },
    sortString(a, b, direction) {
      const A = a.toUpperCase(); // ignore upper and lowercase
      const B = b.toUpperCase(); // ignore upper and lowercase

      if (A < B) {
        return direction === 'desc' ? -1 : 1;
      }

      return direction === 'desc' ? 1 : -1;
    },
  },
};
</script>
