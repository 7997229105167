<template>
  <div class="relative">
    <div class="bg-purple-600 text-white pt-2">
      <div class="content-container md:flex relative">
        <div class="md:w-1/2">
          <h1 class="uppercase leading-none mb-4 mt-16">
            {{ devise.title.text }}
          </h1>
          <div class="font-bold w-3/4 tracking-wide mb-5">
            {{ devise.subtitle.text || 'subtitle' }}
          </div>
          <a
            :href="devise.link.href"
            class="btn rounded-full border-2 inline-block my-4 px-8 py-2 font-bold tracking-wide mb-12"
          >
            {{ devise.link.text || 'See Products' }}
          </a>
        </div>

        <div
          class="md:w-1/2 flex items-center"
          v-if="breakpoint !== 'ultraWideDesktop'"
        >
          <div class="w-full">
            <img
              v-devise-image="{ image: devise.image, breakpoint: breakpoint }"
            />
          </div>
          <div
            class="flex justify-center mb-8 absolute bottom-0 right-0 mr-8"
            v-if="breakpoint !== 'ultraWideDesktop'"
          >
            <div class="container flex pt-10">
              <fiveg-svg class="mr-3" :width="80"></fiveg-svg>
            </div>
          </div>
        </div>
        <div
          class="absolute right-0 top-0"
          v-if="breakpoint === 'ultraWideDesktop'"
        >
          <img
            v-devise-image="{ image: devise.image, breakpoint: breakpoint }"
          />
        </div>
      </div>
    </div>

    <div
      class="content-container flex pt-10"
      v-if="breakpoint === 'ultraWideDesktop'"
    >
      <fiveg-svg class="mr-3"></fiveg-svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoToneHero',
};
</script>
