export default {
  // Blogs
  createBlogs(state, blog) {
    state.blogs.data.push(blog);
  },

  setBlogs(state, payload) {
    state.blogs = payload;
  },

  updateBlogs(state, { blog, data }) {
    state.blogs.data.splice(state.blogs.data.indexOf(blog), 1, data);
  },

  deleteBlogs(state, blog) {
    state.blogs.data.splice(state.blogs.data.indexOf(blog), 1);
  },

  // Products
  createProducts(state, product) {
    state.products.data.push(product);
  },

  setProducts(state, payload) {
    state.products = payload;
  },

  updateProducts(state, { product, data }) {
    state.products.data.splice(state.products.data.indexOf(product), 1, data);
  },

  deleteProducts(state, product) {
    state.products.data.splice(state.products.data.indexOf(product), 1);
  },

  // Products
  createCareers(state, career) {
    state.careers.data.push(career);
  },

  setCareers(state, payload) {
    state.careers = payload;
  },

  updateCareers(state, { career, data }) {
    state.careers.data.splice(state.careers.data.indexOf(career), 1, data);
  },

  deleteCareers(state, career) {
    state.careers.data.splice(state.careers.data.indexOf(career), 1);
  },

  // Industries
  createIndustry(state, industry) {
    state.industries.data.push(industry);
  },

  setIndustries(state, payload) {
    state.industries = payload;
  },

  updateIndustry(state, { industry, data }) {
    state.industries.data.splice(
      state.industries.data.indexOf(industry),
      1,
      data
    );
  },

  deleteIndustry(state, industry) {
    state.industries.data.splice(state.industries.data.indexOf(industry), 1);
  },
};
