<template>
  <!-- eslint-disable max-len -->
  <div>
    <slices
      :slices="slices"
      :currently-selected="currentlySelected"
      @newselection="selectNew"
    />
    <h2 class="text-center text-purple-700 mb-8">
      {{ devise.title.text }}
    </h2>
    <div class="border-t border-purple-700 py-8">
      <div class="flex flex-col">
        <div class="flex flex-col md:flex-row">
          <portal-target
            name="industry-list"
            multiple
            class="md:w-1/5 list-reset flex flex-wrap md:block"
          />
          <portal-target class="md:w-4/5 md:pl-8" name="industry-details" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentlySelected: 'Retail',
    };
  },
  computed: {
    selectedIndustryCopy() {
      return this.devise[`industry${this.currentlySelected}Content`];
    },
    selectedIndustryVideo() {
      return this.devise[`industry${this.currentlySelected}Video`];
    },
  },
  methods: {
    selectNew(newSelection) {
      this.currentlySelected = newSelection;
    },
  },
};
</script>
