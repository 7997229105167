<script>
export default {
  data() {
    return {
      mode: 'professional',
      productId: null,
      products: [],
    };
  },
  computed: {
    currentProduct() {
      let self = this;
      return this.products.find(product => {
        return product.id === self.productId;
      });
    },
    filteredProducts() {
      let self = this;
      return this.products.filter(product => {
        return product.type === self.mode;
      });
    },
  },
  mounted() {
    let self = this;
    window.axios.get('/api/products').then(function(response) {
      self.products = response.data;
      if (self.products.length) {
        self.productId = self.products[0].id;
      }
    });
  },
  methods: {
    openProduct(product) {
      location.href = `/products/${product.id}`;
    },
    openProductFromSelect(event) {
      location.href = `/products/${event.target.value}`;
    },
  },
};
</script>
