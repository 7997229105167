<script>
import Url from '../mixins/Url';
import Strings from '../mixins/Strings';

export default {
  mixins: [Url, Strings],
  data() {
    return {
      industryId: null,
      industries: [],
    };
  },
  computed: {
    currentIndustry() {
      let self = this;
      return this.industries.find(industry => {
        return industry.id === self.industryId;
      });
    },
  },
  mounted() {
    let self = this;
    let industry = this.getQueryVariable('industry');

    window.axios.get('/api/industries').then(function(response) {
      self.industries = response.data;
      if (self.industries.length) {
        self.industryId = self.industries[0].id;
        self.loadIndustryFromSlug(industry);
      }
    });
  },
  methods: {
    loadIndustryFromSlug(industrySlug) {
      let self = this;
      let theIndustry = this.industries.find(
        industry => industrySlug === this.slugify(industry.name)
      );

      if (theIndustry) {
        this.openIndustry(theIndustry);
        this.$nextTick(function() {
          self.scrollToSection('#industry-content');
        });
      }
    },
    openIndustry(industry) {
      this.industryId = industry.id;
    },
    openIndustryFromSelect(event) {
      this.industryId = parseInt(event.target.value);
    },
  },
};
</script>
