/* eslint-disable max-len */
import Vue from 'vue';
import VueRouter from 'vue-router';

const BlogsIndex = () =>
  import(
    /* webpackChunkName: "js/blogs" */ '../components/devise-admin/blogs/Index'
  );
const BlogsEdit = () =>
  import(
    /* webpackChunkName: "js/blogs" */ '../components/devise-admin/blogs/Edit'
  );
const BlogsCreate = () =>
  import(
    /* webpackChunkName: "js/blogs" */ '../components/devise-admin/blogs/Create'
  );
const ProductsIndex = () =>
  import(
    /* webpackChunkName: "js/products" */ '../components/devise-admin/products/Index'
  );
const ProductsEdit = () =>
  import(
    /* webpackChunkName: "js/products" */ '../components/devise-admin/products/Edit'
  );
const ProductsCreate = () =>
  import(
    /* webpackChunkName: "js/products" */ '../components/devise-admin/products/Create'
  );
const CareersIndex = () =>
  import(
    /* webpackChunkName: "js/careers" */ '../components/devise-admin/careers/Index'
  );
const CareersEdit = () =>
  import(
    /* webpackChunkName: "js/careers" */ '../components/devise-admin/careers/Edit'
  );
const CareersCreate = () =>
  import(
    /* webpackChunkName: "js/careers" */ '../components/devise-admin/careers/Create'
  );
const IndustriesIndex = () =>
  import(
    /* webpackChunkName: "js/industries" */ '../components/devise-admin/industries/Index'
  );
const IndustriesEdit = () =>
  import(
    /* webpackChunkName: "js/industries" */ '../components/devise-admin/industries/Edit'
  );
const IndustriesCreate = () =>
  import(
    /* webpackChunkName: "js/industries" */ '../components/devise-admin/industries/Create'
  );

var routes = [
  // // Example of writing custom admin pages
  {
    path: '/devise/blogs',
    name: 'devise-blogs-index',
    components: {
      devise: BlogsIndex,
    },
    meta: {
      title: 'Manage Blogs',
    },
  },
  {
    path: '/devise/blogs/:blogId/edit',
    name: 'devise-blogs-edit',
    components: {
      devise: BlogsEdit,
    },
    meta: {
      title: 'Edit Blogs',
    },
  },
  {
    path: '/devise/blogs/create',
    name: 'devise-blogs-create',
    components: {
      devise: BlogsCreate,
    },
    meta: {
      title: 'Create New Blog',
    },
  },
  {
    path: '/devise/products',
    name: 'devise-products-index',
    components: {
      devise: ProductsIndex,
    },
    meta: {
      title: 'Manage Products',
    },
  },
  {
    path: '/devise/products/:productId/edit',
    name: 'devise-products-edit',
    components: {
      devise: ProductsEdit,
    },
    meta: {
      title: 'Edit Products',
    },
  },
  {
    path: '/devise/products/create',
    name: 'devise-products-create',
    components: {
      devise: ProductsCreate,
    },
    meta: {
      title: 'Create New Product',
    },
  },
  {
    path: '/devise/careers',
    name: 'devise-careers-index',
    components: {
      devise: CareersIndex,
    },
    meta: {
      title: 'Manage Careers',
    },
  },
  {
    path: '/devise/careers/:careerId/edit',
    name: 'devise-careers-edit',
    components: {
      devise: CareersEdit,
    },
    meta: {
      title: 'Edit Careers',
    },
  },
  {
    path: '/devise/careers/create',
    name: 'devise-careers-create',
    components: {
      devise: CareersCreate,
    },
    meta: {
      title: 'Create New Career',
    },
  },
  {
    path: '/devise/industries',
    name: 'devise-industries-index',
    components: {
      devise: IndustriesIndex,
    },
    meta: {
      title: 'Manage Industries',
    },
  },
  {
    path: '/devise/industries/:industryId/edit',
    name: 'devise-industries-edit',
    components: {
      devise: IndustriesEdit,
    },
    meta: {
      title: 'Edit Industries',
    },
  },
  {
    path: '/devise/industries/create',
    name: 'devise-industries-create',
    components: {
      devise: IndustriesCreate,
    },
    meta: {
      title: 'Create New Industry',
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'abstract',
  history: true,
  transitionOnLoad: true,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  // Set the page title
  if (typeof to.meta.title !== 'undefined') {
    document.title = to.meta.title;
  }
  next();
});

export default router;
