<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.47081 13.984C9.44081 12.956 10.7788 12.32 12.2578 12.32C13.7368 12.32 15.0748 12.956 16.0448 13.984L12.2578 18L8.47081 13.984ZM6.72381 12.13C8.14081 10.628 10.0968 9.699 12.2578 9.699C14.4188 9.699 16.3758 10.628 17.7918 12.13L20.1218 9.658C18.1098 7.524 15.3288 6.204 12.2578 6.204C9.18681 6.204 6.40581 7.524 4.39381 9.659L6.72381 12.13ZM2.64581 7.805C5.10581 5.196 8.50481 3.583 12.2578 3.583C16.0108 3.583 19.4098 5.196 21.8698 7.805L24.2578 5.272C21.1868 2.015 16.9448 0 12.2578 0C7.57081 0 3.32881 2.015 0.257812 5.272L2.64581 7.805Z"
      fill="#561EB1"
    />
  </svg>
</template>

<script>
export default {
  name: 'WifiSvg',
  props: {
    width: {
      type: Number,
      default: 25,
    },
  },
};
</script>
