<template>
  <div>
    <div class="hidden lg:flex flex-col lg:flex-row">
      <div class="lg:w-1/4 px-8"></div>
      <div class="flex justify-start lg:w-3/4 ml-1/4 lg:px-12">
        <div
          @click="currentForm = 'support'"
          class="px-8 py-3 lg:-ml-12 cursor-pointer text-sm font-bold "
          :class="{
            'bg-gray-100 text-purple-500': currentForm === 'support',
            'bg-abs-white text-purple-600': currentForm !== 'support',
          }"
        >
          Get Plum Support
        </div>
        <div
          @click="currentForm = 'purchase'"
          class="px-8 py-3 lg:ml-1 cursor-pointer text-sm font-bold "
          :class="{
            'bg-gray-100 text-purple-500': currentForm === 'purchase',
            'bg-abs-white text-purple-600': currentForm !== 'purchase',
          }"
        >
          Inquire about Plum Products
        </div>
      </div>
    </div>

    <div v-if="currentForm === 'support'">
      <div class="flex flex-col lg:flex-row -mt-4">
        <div class="lg:w-1/4 px-8">
          <h3 class="mb-4 mt-8">{{ devise.supportTitle.text }}</h3>
          <div class="text-xs" v-html="devise.supportCopy.text"></div>
        </div>
        <div
          class="lg:w-3/4 my-4 px-8 py-12 lg:px-12 mb-8 lg:mb-12 bg-gray-100"
        >
          <form
            action="https://4829901.extforms.netsuite.com/app/site/crm/externalcasepage.nl?compid=4829901"
            method="post"
            @submit="submitForm"
          >
            <fieldset class="mb-4">
              <label for="firstname"
                >First Name <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.firstname"
                autocomplete="given-name"
                name="firstname"
                id="firstname"
                required
              />
            </fieldset>
            <fieldset class="mb-4">
              <label for="lastname"
                >Last Name <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.lastname"
                autocomplete="family-name"
                name="lastname"
                id="lastname"
                required
              />
            </fieldset>
            <fieldset class="mb-4">
              <label for="phone">Phone</label>
              <input
                type="text"
                v-model="form.phone"
                autocomplete="tel"
                name="phone"
                id="phone"
              />
            </fieldset>
            <fieldset class="mb-4">
              <label for="email"
                >Email <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="email"
                v-model="form.email"
                autocomplete="email"
                name="email"
                id="email"
                required
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="organization"
                >Organization <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.organization"
                autocomplete="organization"
                name="organization"
                id="organization"
                maxlength="25"
                required
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="serialnumber"
                >Serial Number
                <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.serialnumber"
                autocomplete="serialnumber"
                id="serialnumber"
                maxlength="8"
                required
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="title"
                >Nature of Issue
                <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.natureOfIssue"
                autocomplete="false"
                id="title"
                maxlength="50"
                required
              />
              <input
                type="hidden"
                v-model="supportTitle"
                autocomplete="false"
                name="title"
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="description"
                >Detailed description of issue
                <sup class="text-gray-600">* Required</sup></label
              >
              <textarea
                type="text"
                v-model="form.description"
                autocomplete="false"
                name="incomingmessage"
                id="incomingmessage"
                required
              ></textarea>
            </fieldset>

            <input type="hidden" name="compid" value="4829901" /><input
              type="hidden"
              name="formid"
              value="7"
            />
            <input
              type="hidden"
              name="h"
              value="AAFdikaILBWrMi8RfMNEDIiS9LCMCW1-xRDY8lGvvZzEfx--veM"
            />
            <input
              type="hidden"
              name="forminstance"
              value="045c934f-f2ee-464a-925f-79f5317fc261"
            />
            <input type="hidden" name="id" value="" /><input
              type="hidden"
              name="rectype"
              value="-1"
            />
            <input type="hidden" name="whence" value="" /><input
              type="hidden"
              name="submitted"
            />

            <button
              :disabled="submitting"
              type="submit"
              class="btn btn-lg text-lg flex items-center"
              :class="{ 'opacity-50': submitting }"
            >
              <loading-progress
                v-if="submitting"
                :progress="10"
                :indeterminate="true"
                :counter-clockwise="false"
                :hide-background="true"
                size="20"
                rotate
                fillDuration="2"
                rotationDuration="1"
                class="-my-4"
              />
              <span :class="{ 'py-2 px-4': !submitting }">Request Support</span>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="currentForm === 'purchase'">
      <div class="flex flex-col lg:flex-row -mt-4">
        <div class="lg:w-1/4 px-8">
          <h3 class="mb-4 mt-8">{{ devise.purchaseTitle.text }}</h3>
          <div class="text-xs" v-html="devise.purchaseCopy.text"></div>
        </div>
        <div
          class="lg:w-3/4 my-4 px-8 py-12 lg:px-12 mb-8 lg:mb-12 bg-gray-100"
        >
          <form
            action="https://4829901.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4829901"
            enctype="multipart/form-data"
            method="post"
            @submit="submitForm"
          >
            <fieldset class="mb-4">
              <label for="firstname"
                >First Name <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.firstname"
                autocomplete="given-name"
                name="firstname"
                id="firstname"
                required
              />
            </fieldset>
            <fieldset class="mb-4">
              <label for="lastname"
                >Last Name <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.lastname"
                autocomplete="family-name"
                name="lastname"
                id="lastname"
                required
              />
            </fieldset>
            <fieldset class="mb-4">
              <label for="phone">Phone</label>
              <input
                type="text"
                v-model="form.phone"
                autocomplete="tel"
                name="phone"
                id="phone"
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="email"
                >Email <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.email"
                autocomplete="email"
                name="email"
                id="email"
                required
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="city"
                >City <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.city"
                autocomplete="city"
                name="city"
                id="city"
                required
              />
            </fieldset>

            <fieldset class="mb-4">
              <label for="state"
                >State <sup class="text-gray-600">* Required</sup></label
              >
              <select
                v-model="form.state"
                autocomplete="state"
                name="state"
                id="state"
                required
              >
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </fieldset>

            <fieldset class="mb-4">
              <label for="organization"
                >Organization <sup class="text-gray-600">* Required</sup></label
              >
              <input
                type="text"
                v-model="form.organization"
                name="companyname"
                autocomplete="organization"
                id="organization"
                required
              />
            </fieldset>

            <input name="subsidiary" id="subsidiary" type="hidden" value="-1" />
            <input name="leadsource" id="leadsource" type="hidden" value="" />
            <input type="hidden" name="compid" value="4829901" /><input
              type="hidden"
              name="formid"
              value="4"
            /><input
              type="hidden"
              name="h"
              value="AAFdikaIJIpyF7IIuBRwJC-Bj3lQTkzdgowVR1n50LwDI2BFh-s"
            /><input
              type="hidden"
              name="forminstance"
              value="a2f305a4-4f5c-4ca7-87de-686e9eda72bc"
            /><input type="hidden" name="id" value="" /><input
              type="hidden"
              name="rectype"
              value="-1"
            /><input type="hidden" name="whence" value="" /><input
              type="hidden"
              name="submitted"
            />

            <button
              :disabled="submitting"
              type="submit"
              class="btn btn-lg text-lg flex items-center"
              :class="{ 'opacity-50': submitting }"
            >
              <loading-progress
                v-if="submitting"
                :progress="10"
                :indeterminate="true"
                :counter-clockwise="false"
                :hide-background="true"
                size="20"
                rotate
                fillDuration="2"
                rotationDuration="1"
                class="-my-4"
              />
              <span :class="{ 'py-2 px-4': !submitting }"
                >Request Purchasing Information</span
              >
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      submitting: false,
      currentForm: null,
      form: {
        firstname: '',
        lastname: '',
        phone: '',
        organization: '',
        email: '',
        serialnumber: '',
        natureOfIssue: '',
      },
    };
  },

  computed: {
    supportTitle() {
      return `${this.form.serialnumber}: ${this.form.organization} - ${this.form.firstname} ${this.form.lastname}: ${this.form.natureOfIssue}`;
    },
  },

  mounted() {
    this.currentForm = this.devise.defaultForm.value;
  },

  methods: {
    submitForm(e) {
      this.submitting = true;
      console.log(e);
    },
  },
};
</script>
