<template>
  <div class="w-full">
    <div class="flex items-center mb-4">
      <div
        class="p-2 border-4 border-purple-600 rounded-full flex justify-center items-center mr-4"
        style="width:50px;height:50px;"
      >
        <component :is="devise.icon.value" class=" mx-auto"></component>
      </div>
      <h5>{{ devise.title.text }}</h5>
    </div>
    <div class="text-sm leading-loose">{{ devise.description.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'FeatureGridItem',
};
</script>
