import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  adminMenu: [
    {
      icon: 'CreateIcon',
      routeName: 'devise-page-editor',
    },
    // {
    //   icon: 'CubeIcon',
    //   label: 'Site Data',
    //   routeName: 'devise-models',
    //   menu: [
    //     {
    //       label: 'Blogs',
    //       routeName: 'devise-blogs-index',
    //     },

    //     {
    //       label: 'Industries',
    //       routeName: 'devise-industries-index',
    //     },
    //   ],
    // },
    {
      icon: 'ImageIcon',
      label: 'Media',
      routeName: 'media-manager',
    },
    {
      icon: 'CogIcon',
      label: 'Settings',
      routeName: 'devise-settings',
      menu: [
        {
          label: 'Plum Data',
          children: [
            // {
            //   label: 'Products',
            //   routeName: 'devise-products-index',
            // },
            {
              label: 'Careers',
              routeName: 'devise-careers-index',
            },
          ],
        },

        {
          label: 'Site Settings',
          children: [
            {
              label: 'Pages',
              routeName: 'devise-pages-admin',
              permissions: 'manage pages',
            },
            {
              label: 'Users',
              routeName: 'devise-users-admin',
              permissions: 'manage users',
            },
            {
              label: 'Global Meta',
              routeName: 'devise-meta-manage',
              permissions: 'manage meta',
            },
            // {
            //   label: 'Sites',
            //   routeName: 'devise-sites-admin',
            //   permissions: 'manage sites',
            // },
            // {
            //   label: 'Languages',
            //   routeName: 'devise-languages-manage',
            //   permissions: 'manage languages',
            // },
            {
              label: '301 Redirects',
              routeName: 'devise-redirects-admin',
              permissions: 'manage redirects',
            },
          ],
        },
      ],
    },
  ],
  blogs: {
    data: [],
  },
  products: {
    data: [],
  },
  careers: {
    data: [],
  },
  industries: {
    data: [],
  },
};

// A Vuex instance is created by combining the state, the actions,
// and the mutations. Because the actions and mutations are just
// functions that do not depend on the instance itself, they can
// be easily tested or even hot-reloaded (see counter-hot example).
//
// You can also provide middlewares, which is just an array of
// objects containing some hooks to be called at initialization
// and after each mutation.
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
