<template>
  <div class="relative my-8 md:my-0 px-4 md:px-0">
    <div
      class="absolute inset-0 pointer-events-none z-10 flex justify-between items-center"
    >
      <div
        class="credibility-left-arrow cursor-pointer transform rotate-180 ml-2 pointer-events-auto"
        @click="prevSlide"
      >
        <arrow-svg></arrow-svg>
      </div>
      <div
        class="credibility-right-arrow cursor-pointer mr-2 pointer-events-auto"
        @click="nextSlide"
      >
        <arrow-svg></arrow-svg>
      </div>
    </div>

    <div class="md:content-container p-4 text-center text-purple-600">
      <div>
        <div>
          <slick
            ref="CaseStudySlider"
            id="product-swiper"
            :options="{
              slidesToShow: 3,
              slidesToScroll: 1,
              arrows: true,
              autoplay: false,
              dots: false,
              prevArrow: null,
              nextArrow: null,

              responsive: [
                {
                  breakpoint: 900,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            }"
          >
            <!-- slides -->
            <div v-for="(slice, key) in devise.slices" :key="slice.id">
              <component
                :is="getComponent(slice)"
                :devise="slice"
                :slice-index="key"
                style="margin: 20px"
                class="pointer-events-auto"
              />
            </div>
          </slick>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
export default {
  name: 'CaseStudySlider',

  components: {
    Slick,
    DeviseSlice: () =>
      import('devisephp-interface/src/components/slices/Slice.vue'),
  },
  data() {
    return {
      slide: 0,
      leftArrow: null,
      rightArrow: null,
      sliderReference: 'CaseStudySlider',
    };
  },
  mounted() {
    window.bus.$on('devise-loaded', () => {
      this.reInit(this.sliderReference);
    });
  },
  methods: {
    getComponent(slice) {
      console.log(slice.metadata);
      return window.deviseSettings.$deviseComponents[slice.metadata.name];
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs[this.sliderReference].reSlick();
        this.slide = this.getCurrentSlide(this.sliderReference);
      });
    },
    toSlide(i) {
      this.$refs[this.sliderReference].goTo(i);
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    nextSlide() {
      this.$refs[this.sliderReference].next();
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    prevSlide() {
      this.$refs[this.sliderReference].prev();
      this.slide = this.getCurrentSlide(this.sliderReference);
    },
    getCurrentSlide() {
      if (typeof this.$refs[this.sliderReference] !== 'undefined') {
        return this.$refs[this.sliderReference].currentSlide();
      }
      return 0;
    },
  },
};
</script>
