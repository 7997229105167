<template>
  <div class="flex flex-col items-center justify-center w-full">
    <h1 class="mt-24 text-center">Career Opportunities</h1>
    <p class="text-lg text-center max-w-2xl border-b border-gray-200 pb-12">
      We are always looking for the most talented individuals to help push our
      goals at Plum Labs forward. Please have a look at some of the
      opportunities below and see if you are a good fit!
    </p>

    <div class="mt-12">
      <div
        class="mt-12 border-b-2 border-gray-300 pb-12 max-w-4xl"
        v-for="career in careers.data"
        :key="career.id"
      >
        <div class="font-medium text-gray-600 text-xs mb-2 uppercase">
          {{ formatDate(career.created_at) }}
        </div>
        <h4 class="text-purple-800 mb-2">{{ career.name }}</h4>

        <div class="mb-4 text-sm">
          <div class="font-bold text-gray-800 mb-2">Overview</div>
          <div v-html="career.overview"></div>
        </div>

        <button
          @click="showDetails = career"
          class="btn text-purple-600 bg-transparent text-base font-bold border-2 rounded-full border-purple-600 px-8 inline-block"
        >
          Learn More
        </button>
      </div>

      <transition name="fade">
        <div v-if="showDetails" class="fixed z-50 inset-0">
          <div
            class="absolute inset-0 bg-white opacity-75 cursor-pointer"
          ></div>
          <div
            class="absolute inset-0 flex justify-center items-center mt-32"
            @click="showDetails = null"
          >
            <div class="relative">
              <div
                class="absolute top-0 right-0 -mt-16 mr-4 text-indigo-600 cursor-pointer"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </div>
              <div
                class="bg-white max-w-5xl rounded shadow-2xl p-12 mx-12 overflow-y-scroll"
                style="max-height:80vh"
              >
                <h3 class="text-purple-800 mb-2">{{ showDetails.name }}</h3>

                <div class="mb-4" v-if="showDetails.overview">
                  <div class="font-bold text-gray-800 mb-2">Overview</div>
                  <div v-html="showDetails.overview"></div>
                </div>

                <div class="mb-4" v-if="showDetails.responsibilities">
                  <div class="font-bold text-gray-800 mb-2">
                    Responsibilities
                  </div>
                  <div v-html="showDetails.responsibilities"></div>
                </div>

                <div class="mb-4" v-if="showDetails.qualifications">
                  <div class="font-bold text-gray-800 mb-2">Qualifications</div>
                  <div v-html="showDetails.qualifications"></div>
                </div>

                <div class="mb-4">
                  <div class="font-bold text-purple-800 mb-2">To Apply</div>
                  <div>
                    Qualified candidates should send resumes to
                    <a
                      href="mailto:careers@plumcases.com"
                      target="_blank"
                      class="font-medium text-purple-600"
                      >careers@plumcases.com</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CareersIndex',

  data() {
    return {
      showDetails: null,
    };
  },

  computed: {
    ...mapState(['careers']),
  },

  mounted() {
    this.getCareers();
  },

  methods: {
    ...mapActions(['getCareers']),
    formatDate(date) {
      return dayjs(date).format('MMMM D YYYY');
    },
  },
};
</script>
