<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8954 10.4018H17.8188V7.17197C17.8188 3.41281 14.7594 0.353455 11.0002 0.353455C7.24109 0.353455 4.18173 3.41281 4.18173 7.17197V10.4018H3.10512C1.91637 10.4018 0.951904 11.3663 0.951904 12.555V21.1679C0.951904 22.3566 1.91637 23.3211 3.10512 23.3211H18.8954C20.0841 23.3211 21.0486 22.3566 21.0486 21.1679V12.555C21.0486 11.3663 20.0841 10.4018 18.8954 10.4018ZM14.2301 10.4018H7.77042V7.17197C7.77042 5.39108 9.21935 3.94215 11.0002 3.94215C12.7811 3.94215 14.2301 5.39108 14.2301 7.17197V10.4018Z"
      fill="#561EB1"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockSvg',
  props: {
    width: {
      type: Number,
      default: 22,
    },
  },
};
</script>
