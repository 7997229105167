const getters = {
  // Blogs
  blogs: state => {
    return state.blogs;
  },

  // Specific blog based on id
  blog: state => id => {
    return state.blogs.data.find(blog => blog.id === parseInt(id));
  },

  // Products
  products: state => {
    return state.products;
  },

  // Specific product based on id
  product: state => id => {
    return state.products.data.find(product => product.id === parseInt(id));
  },

  // Careers
  careers: state => {
    return state.careers;
  },

  // Specific career based on id
  career: state => id => {
    return state.careers.data.find(career => career.id === parseInt(id));
  },

  // Industries
  industries: state => {
    return state.industries;
  },

  // Specific industry based on id
  industry: state => id => {
    return state.industries.data.find(industry => industry.id === parseInt(id));
  },
};

export default getters;
