<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    viewBox="0 0 221 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.1772 229.336C35.2502 229.336 22.3434 224.575 13.4567 215.053C4.57004 205.32 0.126709 191.462 0.126709 173.477V156.021H33.1343V176.016C33.1343 190.404 39.1646 197.598 51.2251 197.598C63.2855 197.598 69.3158 190.404 69.3158 176.016V126.187C69.3158 111.587 63.2855 104.288 51.2251 104.288C39.1646 104.288 33.1343 111.587 33.1343 126.187V128.091H0.126709L6.47433 3.99509H98.5148V35.7332H37.5777L34.7212 88.7358H35.356C41.9152 77.9449 51.9656 72.5494 65.5072 72.5494C78.2024 72.5494 87.8297 76.8869 94.3889 85.562C100.948 94.2371 104.228 106.827 104.228 123.33V173.477C104.228 191.462 99.7844 205.32 90.8977 215.053C82.011 224.575 69.1042 229.336 52.1772 229.336Z"
      fill="#cacaca"
    />
    <path
      d="M168.145 229.336C151.218 229.336 138.311 224.575 129.425 215.053C120.538 205.32 116.095 191.462 116.095 173.477V56.6804C116.095 38.6954 120.538 24.9422 129.425 15.4208C138.311 5.68779 151.218 0.821289 168.145 0.821289C185.072 0.821289 197.979 5.68779 206.866 15.4208C215.752 24.9422 220.196 38.6954 220.196 56.6804V75.7232H187.188V54.4587C187.188 39.8592 181.158 32.5594 169.097 32.5594C157.037 32.5594 151.007 39.8592 151.007 54.4587V176.016C151.007 190.404 157.037 197.598 169.097 197.598C181.158 197.598 187.188 190.404 187.188 176.016V132.534H169.732V100.796H220.196V173.477C220.196 191.462 215.752 205.32 206.866 215.053C197.979 224.575 185.072 229.336 168.145 229.336Z"
      fill="#cacaca"
    />
  </svg>
</template>

<script>
export default {
  name: 'FiveGSVG',
  props: {
    width: {
      type: Number,
      default: 221,
    },
  },
};
</script>
