<template>
  <div class="content-container py-20">
    <h4 class="text-center mb-16">{{ devise.title.text }}</h4>
    <div class="flex justify-center">
      <div class="grid lg:grid-cols-2 gap-16 w-3/5">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureDescriptionGrid',
};
</script>
