<template>
  <div
    class="prose prose-puple container mx-auto wysiwyg py-12 px-8 lg:px-24"
    v-html="devise.copy.text"
  ></div>
</template>

<script>
export default {
  name: 'GeneralText',
};
</script>
