<template>
  <div>
    <div
      v-tippy="{
        theme: 'light',
        arrow: true,
      }"
      class="absolute flex cursor-pointer justify-center items-center text-xl 
      rounded-full bg-purple-600 border-4 border-abs-white text-white"
      style="height:40px;width:40px"
      :style="position"
      :title="title"
      @click="openModal"
    >
      {{ number }}
    </div>
    <modal v-if="modalOpen" @close="modalOpen = false">
      <slot />
    </modal>
  </div>
</template>

<script>
import Modal from './Modal';

export default {
  components: {
    Modal,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
    x: {
      type: String,
      required: true,
    },
    y: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  computed: {
    position() {
      return {
        top: this.y,
        left: this.x,
      };
    },
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
  },
};
</script>
