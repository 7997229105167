<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9L24 0L15.014 24L12 12L0 9Z" fill="#561EB1" />
  </svg>
</template>

<script>
export default {
  name: 'LocationSvg',
  props: {
    width: {
      type: Number,
      default: 24,
    },
  },
};
</script>
