var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative my-8 md:my-0 px-4 md:px-0"},[_c('div',{staticClass:"absolute inset-0 pointer-events-none z-10 flex justify-between items-center"},[_c('div',{staticClass:"credibility-left-arrow cursor-pointer transform rotate-180 ml-2 pointer-events-auto",on:{"click":_vm.prevSlide}},[_c('arrow-svg')],1),_c('div',{staticClass:"credibility-right-arrow cursor-pointer mr-2 pointer-events-auto",on:{"click":_vm.nextSlide}},[_c('arrow-svg')],1)]),_c('div',{staticClass:"md:content-container p-4 text-center text-purple-600"},[_c('div',[_c('div',[_c('slick',{ref:"CaseStudySlider",attrs:{"id":"product-swiper","options":{
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            autoplay: false,
            dots: false,
            prevArrow: null,
            nextArrow: null,

            responsive: [
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              } ],
          }}},_vm._l((_vm.devise.slices),function(slice,key){return _c('div',{key:slice.id},[_c(_vm.getComponent(slice),{tag:"component",staticClass:"pointer-events-auto",staticStyle:{"margin":"20px"},attrs:{"devise":slice,"slice-index":key}})],1)}),0)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }